<template>
  <div>
    <div v-if="$auth.user[`https://api.pharmaguide.ca/claims/app_metadata`].role === 'superadmin'">
      <router-view></router-view>
      <div v-if="$route.path === '/home'">
        <v-col
          cols="12"
          md="12"
        >
          <pharmacy-list></pharmacy-list>
        </v-col>
      </div>
    </div>
    <div v-else>
      <un-authorized></un-authorized>
    </div>
  </div>
</template>

<script>
import { useFilterStore } from '@/stores/FilterStore'
import unAuthorized from '@/components/dialog/unAuthorized.vue'

export default {
    name: 'Home',
    components: {
        pharmacyList: () => import('@/components/tables/pharmacyList.vue'),
        unAuthorized
    },
    setup() {
        const filterStore = useFilterStore()

        return { filterStore }
    },
    data() {
        return {
            user: this.$auth.user,
        }
    },
    mounted() {},
    methods: {},
}
</script>
