<template>
  <div>
    <v-overlay :value="!userStore.isUserAuthorized">
      <v-alert
        v-if="!userStore.isUserAuthorized"
        type="warning"
        :value="true"
      >
        <v-card
          color="warning"
          flat
        >
          <v-card-title>
            <span class="tw-text-white tw-font-bold tw-text-xl">Your account is pending activation.</span>
          </v-card-title>
          <v-card-actions class="pt-12">
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              @click="logout"
            >
              Log out
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-alert>
    </v-overlay>
  </div>
</template>

<script>

import { useUserStore } from '@/stores/User'

export default {
    setup() {
        const userStore = useUserStore()

        return {
            userStore
        }
    },
    methods: {
        logout() {
            sessionStorage.clear()
            localStorage.clear()
            this.$auth.logout({
                returnTo: `${window.location.protocol}//${window.location.host}`,
            })
        },
    },
}
</script>
